import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { useInView } from "react-intersection-observer"
import respond from "../styles/abstracts/mediaqueries"

const StyledCard = styled.section`
  padding: 0;
  position: relative;
  height: max-content;
  text-align: center;
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transition: all 0.5s ease-in-out;
  margin: 5rem 0 14rem 0;

  ${respond(
    "iphone-12-land",
    css`
      margin: 5rem 0 10rem 0;
    `
  )}
  ${respond(
    "phone-port",
    css`
      margin: 7rem 0 7rem 0;
    `
  )}

  .actual-card {
    background: #fdfdfd;
    box-sizing: border-box;
    box-shadow: 13.8179px 40.4666px 98.6991px rgba(49, 89, 211, 0.12);
    backdrop-filter: blur(25px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: var(--card-border-radius);
    padding: 6rem;
    z-index: 10;

    ${respond(
      "iphone-5-land",
      css`
        border-radius: 20px;
      `
    )}
    ${respond(
      "phone-port",
      css`
        padding: 6rem 2rem;
      `
    )}
    ${respond(
      "iphone-5",
      css`
        padding: 4rem 2rem;
      `
    )}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 600;

    ${respond(
      "iphone-8-land",
      css`
        font-size: 2.2rem;
      `
    )}
    ${respond(
      "phone-port",
      css`
         font-size: 2rem;
      `
    )}
    ${respond(
      "iphone-5",
      css`
         font-size: 1.8rem;
      `
    )}
  }

  blockquote {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    font-style: italic;
  }

  p,
  ul {
    width: 80%;
    margin: 1.5rem auto !important;
    text-align: left;
  }

  ul {
    list-style-position: inside;
    margin: 4rem auto;
    display: block;
    width: 80%;
  }

  li,
  p {
    margin: 1rem 0;

    ${respond(
      "phone-port",
      css`
        margin: 2rem 0;
      `
    )}
  }

  .decorative-inner-card,
  .decorative-second-inner-card {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);

    background: rgba(255, 255, 255, 0.9);
    opacity: 0.8;
    box-sizing: border-box;
    box-shadow: 13.8179px 40.4666px 98.6991px rgba(49, 89, 211, 0.12);
    backdrop-filter: blur(10.6914px);
    border-radius: var(--card-border-radius);
  }

  .decorative-inner-card {
    width: 85%;
    bottom: -3rem;
    z-index: -1;

    ${respond(
      "iphone-12-land",
      css`
        bottom: -2rem;
      `
    )}
    ${respond(
      "phone-port",
      css`
        bottom: -1.5rem;
        width: 90%;
      `
    )}
  }
  .decorative-second-inner-card {
    width: 70%;
    bottom: -6rem;
    z-index: -2;

    ${respond(
      "iphone-12-land",
      css`
        bottom: -4rem;
      `
    )}
    ${respond(
      "phone-port",
      css`
        bottom: -3rem;
        //width: 90%;
      `
    )}
  }

  blockquote,
  p,
  ul,
  .list-item {
    ${respond(
      "ipad-pro-10-port",
      css`
        width: 100%;
      `
    )}
  }
`

const Card = props => {
  const [isInView, setIsInView] = useState(false)

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    initialInView: false,
    fallbackInView: true,
    triggerOnce: true,
  })

  useEffect(() => {
    setIsInView(inView)
  }, [inView, isInView])

  return (
    <StyledCard ref={ref} inView={isInView}>
      <div className="actual-card">{props.children}</div>
      <div className="decorative-inner-card"></div>
      <div className="decorative-second-inner-card"></div>
    </StyledCard>
  )
}

export default Card
